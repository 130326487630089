dmx.Attribute('repeat', 'before', function(node, attr) {
    if (this.node != node) {
        var component = this;
        var prevCount = 0;
        var children = [];
        var template = document.createDocumentFragment();
        var placeholder = document.createComment('Repeat ' + attr.value);
        var RepeatItem = dmx.Component('repeat-item');

        node.parentNode.replaceChild(placeholder, node);
        node.removeAttribute('dmx-repeat');

        template.appendChild(node);

        this.add(attr.argument || 'repeat', []);

        this.$addBinding(attr.value, function(repeat) {
            var RepeatItem = dmx.Component('repeat-item');
            var items = dmx.repeatItems(repeat);
            var fragment = document.createDocumentFragment();

            children.forEach(function(child) {
                component.children.splice(component.children.indexOf(child), 1);
                child.$destroy();
            });

            children = [];

            for (var i = 0; i < items.length; i++) {
                var child = new RepeatItem(template.cloneNode(true), component, items[i]);
                child.$nodes.forEach(function(node) {
                    fragment.appendChild(node);
                    child.$parse(node);
                });
                children.push(child);
                component.children.push(child);
            }

            placeholder.parentNode.insertBefore(fragment, placeholder);
        });
    }
});
